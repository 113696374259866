export default class {
  constructor() {
    // ▼ CheckList
    const checkList = document.querySelectorAll(".list-check_item");
    checkList.forEach((element) => {
      element.innerHTML = '<div class="list-check_inr">' + element.innerHTML + "</div>";
    });

    // ▼ 下線
    const underLine = document.querySelectorAll(".-underLine");
    underLine.forEach((element) => {
      element.innerHTML = '<span class="underLine">' + element.innerHTML + "</span>";
    });

    // ▼ 受託特許分析のSP用レイアウト調整
    const orderFlow = document.querySelectorAll(".list-orderFlow_txt");
    orderFlow.forEach((element) => {
      let cloneElement = element.querySelector("p").cloneNode(true);
      cloneElement.classList.add("-sp");
      element.closest(".list-orderFlow_row").after(cloneElement);
      element.querySelector("p").classList.add("-pc");
    });

    // ▼ フォームの個人情報同意チェック
    const targetButton = document.getElementById("form-confirm");
    const triggerCheckbox = document.getElementById("form-agree");

    if (targetButton !== null) {
      targetButton.disabled = true;
      triggerCheckbox.addEventListener("change", (e) => {
        targetButton.disabled = e.target.checked ? false : true;
      });
    }

    // ▼ ランキングNEWマーク
    // ランキング一覧ページのNEWマーク
    const newLimitItem = document.querySelectorAll(".sec-ranking .list-article_item, .sec-topRanking .list-article_item");
    const nowDate = new Date().toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll("/", "");
    newLimitItem.forEach((element) => {
      const newLimitDate = element.dataset.newLimitDate;
      if (newLimitDate >= nowDate) element.classList.add("-new");
    });
    // ランキング詳細ページのNEWマーク
    const postLimitDateTag = document.querySelector(".l-main");
    if (postLimitDateTag) {
      if (postLimitDateTag.hasAttribute("data-new-limit-date")) {
        const postLimitDate = postLimitDateTag.dataset.newLimitDate;
        if (postLimitDate >= nowDate) postLimitDateTag.classList.add("-new");
      }
    }

    // ▼ ランキング検索のカテゴリ選択チェックボックス
    const checkAll = document.querySelector("#ranking-category .-all");
    const checks = document.querySelectorAll("#ranking-category input:not(.-all)");
    // 全て選択のチェックボックスがクリックされた時
    if (checkAll && checks.length) {
      checkAll.addEventListener("click", () => {
        for (let val of checks) {
          checkAll.checked == true ? (val.checked = true) : (val.checked = false);
        }
      });
      // 個別のチェックボックスがクリックされた時
      checks.forEach((element) => {
        element.addEventListener("click", () => {
          // チェックが1つでも外された時
          if (element.checked == false) checkAll.checked = false;
          // 全てにチェックがされた時
          // console.log(checks.length);
          if (document.querySelectorAll("#ranking-category input:not(.-all):checked").length == checks.length) {
            checkAll.checked = true;
          }
        });
      });
      // ランキングの各カテゴリページで自動チェック
      const rankingCategory = location.pathname.split("/").filter(Boolean).pop();
      if (["total", "scale", "fcit"].includes(rankingCategory)) {
        document.querySelector("#ranking-category .-" + rankingCategory).checked = true;
      } else if (["ranking"].includes(rankingCategory)) {
        const rankingAll = document.querySelectorAll("#ranking-category input");
        for (let i = 0; i < rankingAll.length; i++) {
          rankingAll[i].checked = true;
        }
      }
    }

    // ▼ 表をSP版で横スクロールできるようにdivで囲む
    const wrapTbTarget = document.querySelectorAll(".postDetails table.ranking");
    if (wrapTbTarget.length) {
      wrapTbTarget.forEach((element) => {
        element.outerHTML = '<div class="tb-scroll">' + element.outerHTML + "</div";
      });
    }
  }
}
