export default class {
  constructor() {
    const pageMenu = document.querySelector(".sideNav_menuBox");
    const headerH = document.querySelector(".l-header").offsetHeight;

    window.addEventListener("load", () => {
      pageMenu ? floatingMenu() : "";
    });

    function floatingMenu() {
      const menu_top = pageMenu ? pageMenu.parentElement.getBoundingClientRect().top + window.pageYOffset : "";
      const menu_height = pageMenu ? pageMenu.clientHeight : "";
      const scrollRange = pageMenu ? pageMenu.parentElement.clientHeight - menu_height + menu_top : "";
      let scroll_y = window.scrollY;
      const boxes = document.querySelectorAll(".panel"); // 今回の交差を監視する要素

      const options = {
        root: null, // 今回はビューポートをルート要素とする
        rootMargin: "-20% 0px -80% 0px", // ビューポートの中心を判定基準にする
        threshold: [0, 1], // 閾値は0
      };
      const observer = new IntersectionObserver(doWhenIntersect, options);
      // それぞれのboxを監視する
      boxes.forEach((box) => {
        observer.observe(box);
      });

      /**
       * 交差したときに呼び出す関数
       * @param entries
       */
      function doWhenIntersect(entries) {
        // 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            activateIndex(entry.target);
          }
        });
      }

      /**
       * 目次の色を変える関数
       * @param element
       */
      function activateIndex(element) {
        // すでにアクティブになっている目次を選択
        const currentActiveIndex = document.querySelector(".sideNav_list .-active");
        // すでにアクティブになっているものが0個の時（=null）以外は、activeクラスを除去
        if (currentActiveIndex !== null) {
          currentActiveIndex.classList.remove("-active");
        }
        // 引数で渡されたDOMが飛び先のaタグを選択し、activeクラスを付与
        const newActiveIndex = document.querySelector(`a[href='#${element.id}']`);
        newActiveIndex.classList.add("-active");
      }

      menuScroll();
      window.addEventListener("scroll", menuScroll);

      function menuScroll() {
        scroll_y = window.scrollY;
        if (menu_top - (headerH + 70) < scroll_y && scroll_y < scrollRange - (headerH + 70)) {
          pageMenu.style.top = headerH + 70 + "px";
          pageMenu.classList.add("-fixed");
        } else if (menu_top - (headerH + 70) >= scroll_y) {
          pageMenu.style.top = "0px";
          pageMenu.classList.remove("-fixed");
        } else {
          pageMenu.style.top = scrollRange - menu_top + "px";
          pageMenu.classList.remove("-fixed");
        }
      }
    }

    /*
    //コールバック関数
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          //すでにアクティブになっているものが0個の時（=null）以外は、activeクラスを除去
          const currentActiveImg = document.querySelector("#js-photo .-active");
          if (currentActiveImg !== null) {
            currentActiveImg.classList.remove("-active");
          }

          let targetId = entry.target.id;
          let imgElement = document.querySelector(`.${targetId}`);

          if (imgElement) {
            imgElement.classList.add("-active");
          }

          // すでにアクティブになっているものが0個の時（=null）以外は、activeクラスを除去
          const currentActiveIndex = document.querySelector(".sideNav_list .-active");
          if (currentActiveIndex !== null) {
            currentActiveIndex.classList.remove("-active");
          }

          // 引数で渡されたDOMが飛び先のaタグを選択し、activeクラスを付与
          const newActiveIndex = document.querySelector(`.sideNav_list a[href='#${entry.target.id}']`);
          newActiveIndex.classList.add("-active");
        } else {
          if (entry.target.id === "content1") {
            let getActiveIndex = document.querySelector(".sideNav_list .-active");
            let getActiveImg = document.querySelector("#js-photo .-active");

            if (getActiveIndex !== null) {
              //active削除
              getActiveIndex.classList.remove("-active");
            }
          }
        }
      });
    };

    //オプション
    const options = {
      root: null, // ビューポートをルートとする
      rootMargin: "-10% 0px -90% 0px", // ビューポートの中心を判定基準にする
    };

    // IntersectionObserverオブジェクトを作成
    let observer = new IntersectionObserver(callback, options);

    // 監視したい要素を取得
    let targets = document.querySelectorAll(".panel");

    targets.forEach((target) => {
      if (target) {
        observer.observe(target);
      } else {
        console.log("target element not found");
      }
    });
     */
  }
}
