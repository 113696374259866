// ▼ スクロール時のヘッダーの挙動
export default class {
  constructor() {
    const nav = document.querySelector("#l-header");
    const navFixPos = 500;
    const waitClass = "-wait";
    const adjustClass = "-adjust";
    const fixedClass = "-fixed";

    if (nav !== null) {
      const navHeight = nav.offsetHeight;
      const navPos = navHeight;

      window.addEventListener("scroll", function () {
        let value = this.pageYOffset;
        value > navPos ? nav.classList.add(waitClass) : nav.classList.remove(waitClass, adjustClass);
        value > navFixPos ? nav.classList.add(fixedClass, adjustClass) : nav.classList.remove(fixedClass);
      });
    }
  }
}
