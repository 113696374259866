// ランキングTOP用のカテゴリタブ切り替え

export default class Example {
  constructor() {
    // DOM取得
    const tabMenus = document.querySelectorAll(".nav-seminarCate_link");
    // console.log(tabMenus);

    // イベント付加
    tabMenus.forEach((tabMenu) => {
      tabMenu.addEventListener("click", tabSwitch);
    });

    // イベントの処理
    function tabSwitch(e) {
      e.preventDefault;

      // クリックされた要素のデータ属性を取得
      const tabTargetData = e.currentTarget.dataset.tab;
      // クリックされた要素の親要素と、その子要素を取得
      const tabList = e.currentTarget.closest(".nav-seminarCate");
      // console.log(tabList);
      const tabItems = tabList.querySelectorAll(".nav-seminarCate_link");
      // console.log(tabItems);
      // クリックされた要素の親要素の兄弟要素の子要素を取得
      const tabPanelItems = tabList.nextElementSibling.querySelectorAll(".tabPanel");
      // console.log(tabPanelItems);

      // クリックされたtabの同階層のmenuとpanelのクラスを削除
      tabItems.forEach((tabItem) => {
        tabItem.classList.remove("-active");
      });
      tabPanelItems.forEach((tabPanelItem) => {
        tabPanelItem.classList.remove("-active");
      });

      // クリックされたmenu要素にis-activeクラスを付加
      e.currentTarget.classList.add("-active");
      // クリックしたmenuのデータ属性と等しい値を持つパネルにis-showクラスを付加
      tabPanelItems.forEach((tabPanelItem) => {
        if (tabPanelItem.dataset.panel === tabTargetData) {
          tabPanelItem.classList.add("-active");
        }
      });
    }
  }
}
