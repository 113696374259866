import smoothscroll from "smoothscroll-polyfill";

// ▼ ページ内スクロール
export default class {
  constructor() {
    smoothscroll.polyfill();
    const header = "#l-header";
    const exclusion = [""];

    const headerH = document.querySelector(header) !== null && document.querySelector(header).offsetHeight + 20;
    const pageLink = document.querySelectorAll('a[href^="#"]');

    for (let i = 0; i < pageLink.length; i++) {
      const element = pageLink[i];
      element.addEventListener("click", function (e) {
        e.preventDefault();
        const href = this.getAttribute("href");

        if (exclusion.includes(href)) {
          return false;
        } else {
          const target = href === "#" ? document.querySelector("html") : document.querySelector(href);
          const scroll = target.getBoundingClientRect().top + window.pageYOffset - headerH;
          const positionTop = scroll < 0 ? 0 : scroll;
          window.scrollTo({
            top: positionTop,
            behavior: "smooth",
          });
        }
      });
    }
  }
}
