import { slideUp, slideDown, slideToggle } from "./SlideToggle";
import { getSiblings } from "./getSiblings";

export default class {
  constructor() {
    // ▼ メニュー
    const gnavParentSp = document.querySelectorAll(".l-header .nav-megaMenu_toggle, .l-footer .nav-ftMain_toggle, .sideNav_toggle, .-toggle");
    const gnavItemSp = Array.prototype.slice.call(gnavParentSp, 0);
    // console.log({ gnavParentSp, gnavItemSp });

    gnavItemSp.forEach((element) => {
      element.addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          element.parentNode.classList.toggle("-open");
          slideToggle(element.parentNode.nextElementSibling);
        },
        false
      );
    });

    const hoverMenu = document.querySelectorAll(".nav-header_item.-sub");
    // const hoverMenuArr = Array.prototype.slice.call(hoverMenu, 0);
    hoverMenu.forEach((element) => {
      // console.log(element.parentNode);
      element.addEventListener(
        "mouseover",
        () => {
          element.classList.contains("-open") ? null : slideDown(element.querySelector(".nav-header_subList"));
          element.classList.add("-open");
        },
        false
      );
      element.addEventListener(
        "mouseleave",
        () => {
          slideUp(element.querySelector(".nav-header_subList"));
          element.classList.remove("-open");
        },
        false
      );
    });

    // ▼ プルダウンコンテンツ
    const pullDownBtn = document.querySelectorAll(".dl-pullDown_head, .-pullDown");
    const pullDownItem = Array.prototype.slice.call(pullDownBtn, 0);

    pullDownItem.forEach((element) => {
      element.addEventListener(
        "click",
        function (e) {
          e.preventDefault();
          element.classList.toggle("-open");
          slideToggle(element.nextElementSibling);
        },
        false
      );
    });
  }
}
