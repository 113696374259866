import Swiper from "swiper";
import { Autoplay, EffectFade, Navigation, Pagination, Scrollbar } from "swiper/modules";

export default class {
  constructor() {
    // ▼ TOP MVスライダー
    const topMvObj = ".swiper.topMv";
    const topMvSpeed = 3000;
    const topMvDelay = 3000;

    const topMv = new Swiper(topMvObj, {
      modules: [Autoplay, EffectFade, Pagination],
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      speed: topMvSpeed,
      autoplay: {
        delay: topMvDelay,
        // disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          let num = index + 1;
          return '<div class="' + className + " bar-pagination" + '" style="transition-duration: ' + (topMvSpeed + topMvDelay) + 'ms;"><i class="bar-pagination_inner"></i>' + num + "</div>";
        },
      },
      on: {
        slideChange: function () {
          if (this.realIndex > 0) {
            this.params.autoplay.delay = topMvSpeed;
          }
        },
      },
    });

    // ▼ トピックスバナースライダー
    const pickupSlideObj = ".swiper.pickupSlide";
    const pickupSlide = new Swiper(pickupSlideObj, {
      modules: [Navigation, Scrollbar],
      // loop: true,
      // initialSlide: 0,
      slidesPerView: "auto",
      speed: 1000,
      spaceBetween: 25,
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: true,
      // },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
      },
      breakpoints: {
        // 769px以上
        769: {
          slidesPerView: 5,
          spaceBetween: "2.118%",
        },
      },
    });
  }
}
