export default class {
  constructor() {
    const dataFilter = document.querySelectorAll("[data-theme-filter]");
    const dataTarget = document.querySelectorAll("[data-theme-target]");
    const activeClass = "-active";
    const showClass = "-show";
    let searchArr = [];
    let filterSet = [];
    let targetSet = [];
    // console.log(dataFilter, dataTarget);

    // 絞り込みボタンに使われているテーマタグを抽出
    dataFilter.forEach((btnContent) => filterSet.push(btnContent.dataset.themeFilter));
    // ページ内で使われているテーマタグを抽出
    dataTarget.forEach((cardContent) => {
      let themeTarget = cardContent.dataset.themeTarget;
      themeTarget = themeTarget.split(", ");
      targetSet = Array.from(new Set([...targetSet, ...themeTarget]));
    });
    // 差分抽出して使われていないボタンを削除
    const delTheme = filterSet.filter((i) => targetSet.indexOf(i) == -1);
    delTheme.forEach((delButton) => document.querySelector("[data-theme-filter=" + delButton + "").parentNode.remove());

    // targetの中にarrがすべて含まれていれば true を返す（AND検索）
    const isAllIncludes = (arr, target) => arr.every((el) => target.includes(el));

    // targetの中にarrがひとつでも当てはまったら true を返す独自関数（OR検索）
    const isIncludes = (arr, target) => arr.some((el) => target.includes(el));

    // 絞り込みボタン
    dataFilter.forEach((filterButton) => filterButton.addEventListener("click", buttonSwitch));
    function buttonSwitch() {
      if (this.classList.contains(activeClass)) {
        this.classList.remove(activeClass);
        searchArr = searchArr.filter((value) => value !== this.dataset.themeFilter);
      } else {
        this.classList.add(activeClass);
        searchArr.push(this.dataset.themeFilter);
      }
      // 絞り込みがすべてオフならリセット、ひとつでもオンならフィルタリング
      searchArr.length === 0 ? resetFilter() : filterSearch(searchArr);
    }

    // 絞り込み解除ボタン
    const resetBtn = document.querySelector(".seminarFilter_reset");
    const seminarList = document.querySelector(".seminarList");
    const noResults = document.querySelector(".seminarList_noResults");
    if (resetBtn !== null) resetBtn.addEventListener("click", resetFilter);
    function resetFilter() {
      dataFilter.forEach((filterButton) => filterButton.classList.remove(activeClass));
      dataTarget.forEach((cardContent) => {
        searchArr = [];
        cardContent.classList.add(showClass);
      });
      noResults.style.display = "none";
      seminarList.style.display = "flex";
    }

    // フィルタリング
    function filterSearch(searchArr) {
      let searchTotal = dataTarget.length;
      dataTarget.forEach((cardContent) => {
        let themeTarget = cardContent.dataset.themeTarget;
        themeTarget = themeTarget.split(", ");
        // isAllIncludes(searchArr, themeTarget) ? cardContent.classList.add(showClass) : cardContent.classList.remove(showClass) & --searchTotal; // AND検索
        isIncludes(searchArr, themeTarget) ? cardContent.classList.add(showClass) : cardContent.classList.remove(showClass) & --searchTotal; // OR検索
        cardContent.animate(
          [
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
          ],
          {
            duration: 600,
            fill: "forwards",
          }
        );
      });
      // console.log(searchTotal);
      if (searchTotal === 0) {
        noResults.style.display = "block";
        seminarList.style.display = "none";
      } else {
        noResults.style.display = "none";
        seminarList.style.display = "flex";
      }
    }
  }
}
