import MicroModal from "micromodal";
import { lenis } from "../index";

// ▼ MicroModal
export default class {
  constructor() {
    const imgModal_trigger = document.querySelectorAll("a.list-analysis_modal");
    let imgModal;

    if (imgModal_trigger != null) {
      imgModal = true;
      for (let i = 0; i < imgModal_trigger.length; i++) {
        const element = imgModal_trigger[i];
        element.addEventListener("click", (event) => event.preventDefault());
      }
    }

    //モーダルを開いた時
    const onShow = (modal, trigger, event) => {
      lenis.stop();
      imgModal ? document.querySelector("#modalImage").setAttribute("src", event.target.getAttribute("href")) : "";
    };

    //モーダルを閉じた時
    const onClose = () => {
      lenis.start();
    };

    MicroModal.init({
      onShow,
      onClose,
      // disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }
}
