import { lenis } from "../index";

export default class {
  constructor() {
    // ▼ グローバルメニュー
    const gnavBtn = document.querySelectorAll("#gnavOpen, .gnavBg");
    const gnavBtnArray = Array.prototype.slice.call(gnavBtn, 0);

    gnavBtnArray.forEach((element) => {
      element.addEventListener(
        "click",
        function (e) {
          if (!document.querySelector("body").classList.contains("-gnavOpen")) {
            document.querySelector("body").classList.add("-gnavOpen");
            lenis.stop();
            e.preventDefault();
          } else {
            document.querySelector("body").classList.remove("-gnavOpen");
            lenis.start();
            e.preventDefault();
          }
        },
        false
      );
    });
  }
}
