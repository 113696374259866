import CustomHtml from "./utils/CustomHtml";
import SwiperSetting from "./utils/SwiperSetting";
import Lenis from "@studio-freight/lenis";
import HeaderPosition from "./utils/HeaderPosition";
import Gnav from "./utils/Gnav";
import Accordion from "./utils/Accordion";
import PageScroll from "./utils/PageScroll";
import FloatingMenu from "./utils/FloatingMenu";
import MicroModal from "./utils/MicroModal";
import SeminarFilter from "./utils/SeminarFilter";
import tabMenu from "./utils/TabMenu";
import Ranking from "./utils/Ranking";
import CookieConsent from "./utils/CookieConsent";

export const lenis = new Lenis({
  // wheelMultiplier: 0.9,
  smoothWheel: false,
});

lenis.on("scroll", (e) => {
  // console.log(e);
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

window.addEventListener("DOMContentLoaded", () => {
  new CustomHtml();
  new SwiperSetting();
  new HeaderPosition();
  new Gnav();
  new Accordion();
  new PageScroll();
  new FloatingMenu();
  new MicroModal();
  new SeminarFilter();
  new tabMenu();
  new Ranking();
  new CookieConsent();
});
