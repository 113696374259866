// ▼ Cookieの同意ポップアップ
export default class {
  constructor() {
    const expire = 365; // 有効期限（日）
    let cc = document.querySelector(".box-cookieConsent");
    let ca = document.querySelector("#cookieAgree");
    const flag = localStorage.getItem("displayCookieConsent");
    if (flag != null) {
      const data = JSON.parse(flag);
      if (data["value"] == "true") {
        popup();
      } else {
        const current = new Date();
        if (current.getTime() > data["expire"]) {
          setWithExpiry("displayCookieConsent", "true", expire);
          popup();
        }
      }
    } else {
      setWithExpiry("displayCookieConsent", "true", expire);
      popup();
    }
    ca.addEventListener("click", () => {
      cc.classList.add("-hide");
      setWithExpiry("displayCookieConsent", "false", expire);
    });

    function setWithExpiry(key, value, expire) {
      const current = new Date();
      expire = current.getTime() + expire * 24 * 3600 * 1000;
      const item = {
        value: value,
        expire: expire,
      };
      localStorage.setItem(key, JSON.stringify(item));
    }

    function popup() {
      cc.classList.add("-show");
    }
  }
}
