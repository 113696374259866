// ランキングTOP用のカテゴリタブ切り替え

export default class Ranking {
  constructor() {
    //DOM
    const rankingSearchForm = document.querySelector(".form-rankingSearch form");
    let newsMedia;
    let cardContainer;
    let loader;
    let seminarInfo;
    let rankingSearchFormButton;
    let resultHeaderNum;
    let sortBtns;
    let noResult;

    //設定
    const cardLimit = 100;
    const cardIncrease = 10;
    const pageCount = Math.ceil(cardLimit / cardIncrease);
    let categoryMaxNum;
    let currentPage = 1;
    const assetTxt = "特許資産規模ランキング";
    const checkedTxt = "他社牽制力ランキング";
    const totalTxt = "特許総合力";
    const assetOutputTxt = "特許資産規模ランキング";
    const checkedOutputTxt = "他社牽制力ランキング";
    const totalOutputTxt = "特許総合力ランキング";
    let noResultFlg = true;
    let moveFlg = false;

    let totalResultsNum = 0;
    let sumStackNum = 0; //一覧ページを合計！除外するために記事ページを数える
    let stackNum = 0; //一覧ページを除外するために記事ページを数える
    let insufficientNum = 0; //一覧ページの数を数える

    //検索パラメータ
    let freeWord = ``;
    let categorys = [`特許総合力`, `特許資産規模`, `他社牽制力`];
    // let categorys = [``, ``, ``];
    let industry = ``;
    let year = ``;
    let sort = `date`;
    // let sort = ``;

    //API
    let url;
    let query;
    let currentJsonDate = "";

    //url
    let currentUrl = window.location;

    //各種設定
    function init() {
      newsMedia = document.querySelector(".newsMedia");
      cardContainer = document.querySelector(".list-article");
      seminarInfo = document.querySelector(".seminarInfo");
      rankingSearchFormButton = rankingSearchForm.querySelector("button");
      // resultHeaderNum = document.querySelector(".resultHeader_num");
      sortBtns = document.querySelectorAll(".resultHeader_sortItem .resultHeader_sortLink");

      // エンターで送信されないようにする
      rankingSearchForm.setAttribute("onsubmit", "return false;");

      onlyStyle();
      rotateLoaderSet();

      //設定
      categoryMaxNum = document.querySelector("#ranking-category").childElementCount;

      // 取得した値をコンソールに出力
      // console.log("-----情報更新前-----");
      // console.log("freeWord:", freeWord);
      // console.log("categorys:", categorys);
      // console.log("industry:", industry);
      // console.log("year:", year);
      // console.log("sort:", sort);

      //並び替えボタンアクティブの決定
      // sortSelect();
    }

    function generateQuery() {
      function isAllNull(array) {
        return array.every((element) => element === null);
      }
      //カテゴリが空だったら
      if (isAllNull(categorys) || categorys.length === 0) {
        categorys = ["特許総合力", "特許資産規模", "他社牽制力"];
      }
      query = `${freeWord}${industry ? `"${industry}"` : ""}${categorys[0] ? `+intitle:\\"${categorys[0]}\\"` : ""}${categorys[1] ? `+OR+intitle:\\"${categorys[1]}\\"` : ""}${categorys[2] ? `+OR+intitle:\\"${categorys[2]}\\"` : ""}${year ? `+${year}` : ""}`;
    }

    // JSONデータを取得する関数
    async function fetchJSONData(query, num, start, sort) {
      try {
        loader.style.display = "block";
        const url = new URL(`${currentUrl.origin}/assets/php/fetch_data.php`);
        // const url = new URL("http://localhost:8080/assets/php/fetch_data.php");
        url.searchParams.append("q", query);
        url.searchParams.append("num", num);
        url.searchParams.append("start", start);
        url.searchParams.append("sort", sort);

        let response = await fetch(url);
        if (!response.ok) {
          console.log("First attempt failed, retrying...");
          response = await fetch(url);
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        loader.style.display = "none";

        // console.log("jsonData", jsonData);
        return jsonData;
      } catch (error) {
        // console.error("Fetch error:", error);
        loader.style.display = "none";

        return null;
      }
    }

    let throttleTimer;
    const throttle = (callback, time) => {
      if (throttleTimer) return;

      throttleTimer = true;

      setTimeout(() => {
        callback();
        throttleTimer = false;
      }, time);
    };

    function createElementWithClass(tagName, className) {
      const element = document.createElement(tagName);
      element.classList.add(className);
      return element;
    }

    function createParagraphWithText(targetDom, textContent) {
      targetDom.innerHTML = textContent;
    }

    // タイトルから適切なタグテキストを取得する関数
    function getTagText(title) {
      if (title.includes(assetTxt)) return assetOutputTxt;
      if (title.includes(checkedTxt)) return checkedOutputTxt;
      if (title.includes(totalTxt)) return totalOutputTxt;
      return null; // タグテキストが見つからない場合はnullを返す
    }

    // URLから年を抽出する関数
    function extractYearFromURL(url) {
      const matches = url.match(/\/20(\d{2})\//);
      if (matches && matches[1]) {
        return "20" + matches[1]; // 年を "20YY" の形式に変換して返す
      }
      return null; // 年が見つからない場合はnullを返す
    }

    function dateTransform(originalText) {
      const daysAgoTxt = "days ago";
      // 日付部分を取得 // "6 days ago <b>...</b> 特許資産規模ランキングに関するランキング一覧です。"
      let part = originalText.split("\u003cb\u003e...\u003c/b\u003e ");
      // "Nov 17, 2022" console.log("datePart", datePart);

      // 分割できたかどうかをチェック
      if (part.length > 1) {
        let datePart = part[0];
        //もし日付の形式が＊ days ago
        if (datePart.includes(daysAgoTxt)) {
          // console.log("originalText", originalText);

          // 指定の文字列で分割し、その前の部分を数値に変換
          const daysAgo = Number(originalText.split(daysAgoTxt)[0]);
          // console.log("num", daysAgo);

          // 現在の日付からdaysAgo日引いた日付を計算
          const pastDate = new Date(new Date().setDate(new Date().getDate() - daysAgo));
          // console.log(pastDate);

          datePart = pastDate;
        }

        // 日付を変換
        const date = new Date(datePart);
        const year = date.getFullYear();
        const month = date.toLocaleDateString("default", { month: "long" });
        const day = date.getDate();

        // 日付を目的の形式に変換
        const formattedDate = `${year}/${month.split("月")[0]}/${day}`;

        // 日付を含むテキスト部分を取得
        let textPart = originalText.split("<b>...</b>")[1];

        // 変換した日付とテキストを連結して目的の形式に変換
        return `${formattedDate} ー ${textPart}`;
      } else {
        // 分割できなかった場合の処理
        return `${part}`;
      }
    }

    function createCard(jsonData) {
      if (!jsonData) return; // JSONデータがない場合は処理を中断

      const card = createElementWithClass("li", "list-article_item");
      const anchor = createElementWithClass("a", "list-article_link");
      const tags = createElementWithClass("ul", "list-article_tags");
      const categoryTag = createElementWithClass("li", "list-article_tag");
      const yearTag = createElementWithClass("li", "list-article_tag");
      const title = createElementWithClass("p", "list-article_title");
      const industries = createElementWithClass("p", "list-article_industries");
      const summary = createElementWithClass("p", "list-article_summary");

      // タイトルの追加
      //json内にmetatagsがないパターンがあるので条件分岐
      // createParagraphWithText(title, jsonData.pagemap.metatags[0]["og:title"]);
      let titleTxt = "";
      if (jsonData && jsonData.pagemap && jsonData.pagemap.metatags && jsonData.pagemap.metatags[0] && jsonData.pagemap.metatags[0]["og:title"]) {
        titleTxt = jsonData.pagemap.metatags[0]["og:title"];
      } else {
        titleTxt = jsonData.title;
      }
      //タイトルをdomに追加
      createParagraphWithText(title, titleTxt);

      // タグの追加
      const tagText = getTagText(titleTxt);
      if (tagText) {
        createParagraphWithText(categoryTag, tagText);
        tags.appendChild(categoryTag);
      }

      // URLに '/20**/' が含まれている場合の処理
      // タグが特許総合力ランキングではない時のみ
      if (jsonData.link.includes("/20") && tagText !== totalOutputTxt) {
        const year = extractYearFromURL(jsonData.link);
        createParagraphWithText(yearTag, `${year}年版`);
        tags.appendChild(yearTag);
      }

      // インダストリの追加
      // const oGDescription = "　弊社はこのほど、独自に分類した「ゲーム・エンターテインメント」業界の企業を対象に、各社が保有する特許資産を質と量の両面から総合評価した「ゲーム・エンターテインメント業界　特許資産規模ランキング」をまとめました。2019年4月1日から2020年3月末までの1年間に登録さ#食品,#エンターテイメント";
      let oGDescription = "";

      //json内にmetatagsがないパターンがあるので条件分岐
      if (jsonData && jsonData.pagemap && jsonData.pagemap.metatags && jsonData.pagemap.metatags[0] && jsonData.pagemap.metatags[0]["og:description"]) {
        oGDescription = jsonData.pagemap.metatags[0]["og:description"];
      } else {
        oGDescription = jsonData.description;
      }

      if (oGDescription) {
        let labels = "#" + oGDescription.split("#").slice(1).join("#");
        createParagraphWithText(industries, labels);
      }

      // スニペットの追加
      createParagraphWithText(summary, dateTransform(jsonData.htmlSnippet));

      // リンクの設定
      anchor.setAttribute("href", jsonData.link);

      // DOMの組み立て
      anchor.appendChild(tags);
      anchor.appendChild(title);
      anchor.appendChild(industries);
      anchor.appendChild(summary);
      card.appendChild(anchor);

      if (!jsonData.link.includes(".html") || jsonData.link.includes("/media/") || jsonData.link.includes("index_") || !/^\/ranking\//.test(new URL(jsonData.link).pathname)) {
        // console.log(jsonData.link, "除外URL発見！");
        insufficientNum++;
        sumStackNum++;
      } else if (jsonData.link.includes("/20") && jsonData.pagemap.metatags) {
        //urlが/20からを含む ＆ metatagsがjson内に含まれてる場合のみカードを作成
        cardContainer.appendChild(card);
        stackNum++;
      } else {
        cardContainer.appendChild(card);
        stackNum++;
      }
    }

    function addCards(pageIndex) {
      // JSONデータを取得してコンソールに表示

      generateQuery();

      fetchJSONData(query, cardIncrease, currentPage, sort)
        .then((jsonData) => {
          if (jsonData) {
            // ここで取得したJSONデータを処理する
            currentJsonDate = jsonData.items;

            // console.log("query:", query);
            // console.log("currentJsonDate", currentJsonDate);

            if (currentJsonDate) {
              currentPage = pageIndex;

              const startRange = 0;
              const endRange = currentJsonDate.length;

              for (let i = startRange; i <= endRange; i++) {
                createCard(currentJsonDate[i]);
              }

              if (noResult) {
                noResult.style.display = "none";
              }

              // 初回のみ10件json出力後足りなかった数と足りた数をチェック
              if (insufficientNum !== 0 && stackNum <= 5) {
                currentPage += cardIncrease;

                generateQuery();
                addCards(currentPage);

                //リセット
                insufficientNum = 0;
              } else {
                //足りていたらリセット
                stackNum = 0;
              }

              //検索結果数更新
              totalResultsNum = jsonData.queries.request[0].totalResults;
              // totalResult();
            } else {
              // console.log("itemがなくなった");
              removeInfiniteScroll();

              // console.log("sumStackNum", sumStackNum);
              // console.log("stackNum", stackNum);
              // console.log("insufficientNum", insufficientNum);

              // console.log("noResultFlg", noResultFlg);
              // 検索して0件だった場合
              if (noResultFlg && cardContainer.childElementCount === 0) {
                totalResultsNum = 0;
                noResultFlg = false;
                noResult = createElementWithClass("li", "noResult");
                createParagraphWithText(noResult, "該当する記事が見つかりませんでした。条件を変更して再度検索をお願いします。");
                cardContainer.appendChild(noResult);
                // totalResult();
              }
            }
          } else {
            console.log("JSONデータの取得に失敗しました。");
            removeInfiniteScroll();
          }
        })
        .catch((error) => {
          // console.error("エラー:", error);
          removeInfiniteScroll();
        });
    }

    const handleInfiniteScroll = () => {
      throttle(() => {
        //画面一番下についた時（現在の画面下の位置よりもページの高さが小さい場合）
        const endOfPage = window.innerHeight + window.pageYOffset >= cardContainer.offsetHeight;

        //cardContainerの一番下についた時
        if (endOfPage) {
          currentPage += cardIncrease;

          generateQuery();

          addCards(currentPage);
        }
        if (currentPage === pageCount) {
          removeInfiniteScroll();
        }
      }, 1000);
    };

    const removeInfiniteScroll = () => {
      // console.log("STOP!!!!", currentPage, pageCount);
      window.removeEventListener("scroll", handleInfiniteScroll);

      if (100 <= currentPage) {
        //100件出したらアラート出す
        seminarInfo.classList.add("-max");
      }
    };

    function listReset() {
      seminarInfo.classList.remove("-max");
      while (cardContainer.firstChild) {
        cardContainer.removeChild(cardContainer.firstChild);
      }
    }

    function resetForm() {
      // フォームの値を取得
      freeWord = document.querySelector("[name='ranking-freeWord']").value;
      categorys = Array.from(document.querySelectorAll("[name='ranking-category']:checked")).map((input) => input.value);
      industry = document.querySelector("[name='ranking-industries']").value;
      year = document.querySelector("[name='ranking-year']").value;

      // 記事カテゴリの全てを選択をしている場合はじめの配列を消す
      if (categorys.length === categoryMaxNum) {
        categorys.shift();
      }

      // URLパラメーターを構築
      const params = new URLSearchParams();
      params.append("freeWord", freeWord);

      //カテゴリーを別のキーでマッピング
      categorys.forEach((value, index) => {
        params.append("category" + index, value);
      });

      params.append("industry", industry ? industry.split("%23")[1] : "");
      params.append("year", year);

      // if (param === undefined) {
      //   param = "";
      // }
      params.append("sort", sort);

      history.replaceState("", "", `?${params}`);

      return params;
    }

    function resetFormSubmit(param) {
      const params = resetForm(param);

      //以下確認用
      // const decodedParams = decodeURIComponent(params.toString());
      // console.log("decodedParams:", decodedParams);

      // /ranking/searchに移動
      window.location.href = "/ranking/search/?" + decodeURIComponent(params.toString());
    }

    function totalResult() {
      const resultNum = totalResultsNum - sumStackNum;
      // console.log("合計数", totalResultsNum);
      // console.log("除外した数：", sumStackNum);
      // console.log("合計数 - 除外した数：", resultNum);

      if (resultNum >= 0) {
        resultHeaderNum.textContent = `（約${resultNum}件）`;
      } else {
        resultHeaderNum.textContent = `（約0件）`;
      }
    }

    function formValueSet() {
      //urlパラメータに設定したvalueをformへ設定
      document.querySelector("[name='ranking-freeWord']").value = freeWord;
      const postCategoryInputs = Array.from(document.querySelectorAll("[name='ranking-category']"));
      postCategoryInputs.forEach((input) => {
        // input要素のvalueがcategorysに含まれる場合、checked属性を追加する
        if (categorys.includes(input.value)) {
          input.checked = true;
        } else {
          input.checked = false;
        }
      });
      //全てチェック入ってる場合「全て選択をchecked」
      if (postCategoryInputs[1].checked === true && postCategoryInputs[2].checked === true && postCategoryInputs[3].checked === true) {
        postCategoryInputs[0].checked = true;
      }

      document.querySelector("[name='ranking-industries']").value = industry ? `%23${industry}` : "";
      document.querySelector("[name='ranking-year']").value = year;
    }

    function onlyStyle() {
      const rotateStyle = document.createElement("style");
      rotateStyle.textContent = `

  .noResult{
      text-align:center;
      font-weight: bold;
      }

  @media not screen and (min-width: 768px) {
    .noResult{
      text-align:left;
      }
    }


  .rotateLoader {
      display: none;
      width: 64px;
      height: 64px;
      position: fixed;
      inset: 0px;
      margin: auto;
      animation: rotateAnim 1s linear infinite;
    }

  @keyframes rotateAnim {
      0% {
        rotate:0deg;
      }

      100% {
        rotate:360deg;
      }
    }
        `;
      document.head.appendChild(rotateStyle);
    }

    function rotateLoaderSet() {
      loader = createElementWithClass("img", "rotateLoader");
      loader.setAttribute("src", "/assets/images/ranking/searchLoader.png");

      newsMedia.appendChild(loader);
    }

    function sortSelect() {
      if (sort) {
        sortBtns[0].classList.add("-active");
        sortBtns[1].classList.remove("-active");
      } else {
        sortBtns[0].classList.remove("-active");
        sortBtns[1].classList.add("-active");
      }
    }

    window.onload = function () {
      if (rankingSearchForm) {
        init();
        const currentPath = currentUrl.pathname;
        const currentDirectory = currentPath.substring(0, currentPath.lastIndexOf("/"));
        // console.log("現在のディレクトリ:", currentDirectory);

        //ページ判断
        if (currentDirectory === "/ranking/search") {
          const urlParams = currentUrl.search;

          //URLパラメーターが含まれていれば
          if (urlParams) {
            // 別のページでURLパラメーターの値を取得
            const searchParams = new URLSearchParams(urlParams);

            freeWord = searchParams.get("freeWord");

            //categorysに初期値の値があるため配列をリセット
            categorys.length = 0;
            categorys.push(searchParams.get("category0"));
            categorys.push(searchParams.get("category1"));
            categorys.push(searchParams.get("category2"));
            industry = searchParams.get("industry") ? searchParams.get("industry") : "";

            year = searchParams.get("year") ? searchParams.get("year")?.replace(" ", "+") : "";
            sort = searchParams.get("sort");

            // 取得した値をコンソールに出力
            // console.log("-----情報更新後-----");
            // console.log("freeWord:", freeWord);
            // console.log("categorys:", categorys);
            // console.log("industry:", industry);
            // console.log("year:", year);
            // console.log("sort:", sort);

            generateQuery();

            formValueSet();

            //並び替えボタンアクティブの決定
            sortSelect();
          }

          formValueSet();
          listReset();
          addCards(currentPage);

          //検索ボタン押下時処理
          rankingSearchFormButton.addEventListener("click", function (event) {
            // デフォルトの送信動作をキャンセル
            event.preventDefault();

            if (!moveFlg) {
              moveFlg = true;

              //検索ボタン押下遅延
              setTimeout(() => {
                moveFlg = false;
              }, 1000);

              // フォームの値を取得
              freeWord = document.querySelector("[name='ranking-freeWord']").value;
              categorys = Array.from(document.querySelectorAll("[name='ranking-category']:checked")).map((input) => input.value);
              industry = document.querySelector("[name='ranking-industries']").value;
              year = document.querySelector("[name='ranking-year']").value;
              sort = "date";

              // 記事カテゴリの全てを選択をしている場合はじめの配列を消す
              if (categorys.length === categoryMaxNum) {
                categorys.shift();
              }

              //並び替えボタンアクティブの決定
              sortSelect();

              // 取得した値をコンソールに出力
              // console.log("-----検索ボタン押下-----");
              // console.log("freeWord:", freeWord);
              // console.log("categorys:", categorys);
              // console.log("industry:", industry);
              // console.log("year:", year);
              // console.log("sort:", sort);

              // 現在のページも初期化
              currentPage = 1;

              generateQuery();

              sumStackNum = 0;
              insufficientNum = 0;
              noResultFlg = true;

              listReset();
              addCards(currentPage);

              //
              resetForm();

              window.addEventListener("scroll", handleInfiniteScroll);
            }
          });

          //並び替えボタン押下時処理
          sortBtns.forEach((btn) => {
            btn.addEventListener("click", (event) => {
              event.preventDefault();
              if (sortBtns[0] === btn) {
                //新着順をクリックした場合
                sort = `date`;
              } else {
                sort = ``;
              }

              resetFormSubmit(sort);
            });
          });

          window.addEventListener("scroll", handleInfiniteScroll);
        } else {
          // /ranking/search以外のページ

          rankingSearchFormButton.addEventListener("click", function (event) {
            // デフォルトの送信動作をキャンセル
            event.preventDefault();

            resetFormSubmit();
          });
        }
      }
    };
  }
}
